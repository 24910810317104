::placeholder {
  color: lightgrey;
}
.MuiTableCell-root {
  font-size: 12px !important;
}
html {
  height: 100%;
}
* {
  font-family: "IBM Plex Sans", "-apple-system", "BlinkMacSystemFont",
    "Segoe UI", Helvetica, Arial, sans-serif;
  font-size: 12px;
}
body {
  height: 90%;
  margin: 0;
  padding: 0;
  background-color: white;
  font-size: 12px;
  line-height: 1.428571429;
  font-weight: 400;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

#root {
  height: 100%;
}
